import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#48bd96',
        },
        secondary: {
            main: '#ffe066',
        },
        error: {
            main: '#f25f5c',
        },
        success: {
            main: '#247ba0',
        },
        text: {
            main: `#000`,
        },
        background: {
            default: '#fafafa',
            paper: '#fff'
        },
    },
});

export default theme;